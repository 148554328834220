





































































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import AddElement from "@monorepo/uikit/src/components/tableViews/AddElement.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import OikInfoModal from "@monorepo/catalog/src/views/OikView/components/OikInfoModal.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/oikView/tableHeaders";
import { defineComponent, ref } from "@vue/composition-api";
import { module as OikCatalogView } from "@monorepo/catalog/src/store/modules/oikCatalogView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/catalog/src/views/OikView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/oikView/viewTitle";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { Route } from "vue-router";
import { IOikElement } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { fields } from "@monorepo/utils/src/variables/projectsData/oikView/fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import RestoreFooterButton from "@monorepo/uikit/src/components/tableViews/RestoreFooterButton.vue";
import DeleteFooterButton from "@monorepo/uikit/src/components/tableViews/DeleteFooterButton.vue";
import { SyncStatuses } from "@monorepo/catalog/src/views/OikView/types/syncStatuses";
import { convertApiItemToUi } from "@monorepo/catalog/src/views/OikView/utils/convertApiItemToUi";
import useMoveByAuthorities from "@monorepo/utils/src/composables/useMoveByAuthorities";
import StatusCell from "@monorepo/catalog/src/views/OikView/components/StatusCell.vue";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/oikView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/catalog/src/views/OikView/utils/defaultCurrentFilters";
import useFooterActions from "@monorepo/utils/src/composables/useFooterActions";

const SECTION_NAME = "oikCatalogView";
const pluralFormTitles = ["ОИК", "ОИКа", "ОИКов"];

export default defineComponent({
  name: "OikView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    AddElement,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    OikInfoModal,
    Footer,
    ExportFooterButton,
    RestoreFooterButton,
    DeleteFooterButton,
    StatusCell,
  },
  data() {
    return {
      breadcrumbs,
      ModalType,
      viewTitle,
      viewUniqKey,
      isOnceSelected: false,
      educationIds,
      pluralFormTitles,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.OIK_CODE, fields.OIK_NAME, fields.OIK_ID],
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, ["isOpenFilters", "cells", "isSelectAll", "infiniteId", "data", "totalLength", "selectedIds", "isTableLoading"]),
    isCanRefresh(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_LOG_UPDATE_MODIFY);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_EXPORT);
    },
    isCanDelete(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_DELETE);
    },
    isCanCreate(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CREATE);
    },
    isShowGant(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_CASE_TRANSFER_LIST);
    },
    resultTableHeaders(): typeof tableHeaders {
      return tableHeaders.filter((item) => {
        return ![fields.HANDOVER_DATE_TO, fields.HANDOVER_DATE_FROM].includes(item.value) ? true : this.isShowGant;
      });
    },
    isCanEdit(): boolean {
      return isAuthorityExist(this.user, authorities.OIK_MODIFY);
    },
    isShowFooter(this: {
      isSelectAllElement: boolean;
      selectedIds: Record<string, boolean>;
      isShowExport: boolean;
      isShowDeleteBtn: boolean;
      isShowRestoreBtn: boolean;
      isShowSyncBtn: boolean;
    }): boolean {
      return (
        (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) &&
        (this.isShowExport || this.isShowDeleteBtn || this.isShowRestoreBtn || this.isShowSyncBtn)
      );
    },
    canBeSynchronizeCodes(): string[] {
      const synchronizeItemsObject = this.data.reduce((result: { [key: string]: IOikElement }, item: IOikElement) => {
        result[item.id] = item;
        return result;
      }, {});

      return Object.entries(this.selectedIds)
        .filter(([key, value]) => {
          return value && ![SyncStatuses.PENDING, SyncStatuses.SUCCESS].includes(synchronizeItemsObject[key].synchronizationStatus);
        })
        .map(([key]) => synchronizeItemsObject[key].code?.toString());
    },
    canBeSynchronizeIds(): string[] {
      const synchronizeStatusesObject = this.data.reduce((result: { [key: string]: string }, item: IOikElement) => {
        result[item.id] = item.synchronizationStatus;
        return result;
      }, {});

      return Object.entries(this.selectedIds)
        .filter(([key, value]) => {
          return value && ![SyncStatuses.PENDING, SyncStatuses.SUCCESS].includes(synchronizeStatusesObject[key]);
        })
        .map(([key]) => key.toString());
    },
    cannotBeSynchronizeIds(): string[] {
      const synchronizeStatusesObject = this.data.reduce((result: { [key: string]: string }, item: IOikElement) => {
        result[item.id] = item.synchronizationStatus;
        return result;
      }, {});

      return Object.entries(this.selectedIds)
        .filter(([key, value]) => {
          return value && [SyncStatuses.PENDING, SyncStatuses.SUCCESS].includes(synchronizeStatusesObject[key]);
        })
        .map(([key]) => key.toString());
    },
    isShowSyncBtn() {
      const canBeSynchronize = this.data.filter(
        (item: IOikElement) => ![SyncStatuses.PENDING, SyncStatuses.SUCCESS].includes(item.synchronizationStatus as SyncStatuses)
      );
      const cannotBeSynchronize = this.data.filter((item: IOikElement) =>
        [SyncStatuses.PENDING, SyncStatuses.SUCCESS].includes(item.synchronizationStatus as SyncStatuses)
      );

      if (this.isSelectAll && canBeSynchronize.length && !cannotBeSynchronize.length) {
        return true;
      }

      return (this.canBeSynchronizeIds as string[]).length && !(this.cannotBeSynchronizeIds as string[]).length;
    },
    headers(): typeof tableHeaders {
      return tableHeaders.filter((item) => {
        return ![fields.HANDOVER_DATE_TO, fields.HANDOVER_DATE_FROM].includes(item.value) ? true : this.isShowGant;
      });
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, [
      "getEventList",
      "addOpenedId",
      "getExportData",
      "setIsOpenFilters",
      "refreshScroll",
      "restoreEntity",
      "synchronizeOiks",
    ]),
    ...mapActions(SECTION_NAME, {
      deleteElement: "deleteOik",
    }),
    changeType(type: ModalType) {
      this.type = type;
      if (type === ModalType.NEW) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    openModalIfNeed(this: { isOnceSelected: boolean; data: IOikElement[]; $route: Route; addOpenedId: (id: string) => void; showOikModal: boolean }) {
      if (this.isOnceSelected) {
        return;
      }

      this.isOnceSelected = true;

      if (this.$route.query?.isOpenModal && this.data.length) {
        if (this.$route.query?.id) {
          this.addOpenedId(this.$route.query?.id as string);
          this.showOikModal = true;
          return;
        }
        this.addOpenedId(this.data[0].id);
        this.showOikModal = true;
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
        this.showOikModal = true;
      }
    },
    toggleModal(value: boolean, type?: ModalType) {
      this.showOikModal = value;
      this.type = type || ModalType.NEW;
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event.id) {
            this.addOpenedId(event.id);
            this.openCardModal(event.isDeleted as boolean);
          }
          break;
        case fields.FUNDS_LIST: {
          this.moveByAuthorities(header, "/dictionaries/fund", {
            id: event.id as string,
            isOpenModal: "1",
          });
          break;
        }
        default:
          break;
      }
    },
    openCardModal(
      this: {
        toggleModal: (value: boolean, type?: ModalType) => void;
        isCanEdit: boolean;
      },
      isDeleted: boolean
    ) {
      if (isDeleted) {
        return this.toggleModal(true, ModalType.READ);
      }
      this.toggleModal(true, this.isCanEdit ? ModalType.EDIT : ModalType.READ);
    },
  },
  setup(props, { root }) {
    const sectionName = ref(SECTION_NAME); // sectionName === store module name
    const filtersLoaded = ref(false);
    const showOikModal = ref(false);
    const type = ref(ModalType.EDIT);
    const moveByAuthorities = useMoveByAuthorities(root);

    const openModal = () => {
      if (root.$store.getters[`${SECTION_NAME}/openedId`]) {
        showOikModal.value = true;
        const isCanEdit = !!root.$store.getters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.OIK_MODIFY;
        });
        type.value = isCanEdit ? ModalType.EDIT : ModalType.READ;
      }
    };

    const { isShowDeleteBtn, isShowRestoreBtn, openDeleteModal, openRestoreModal } = useFooterActions(
      root,
      SECTION_NAME,
      pluralFormTitles,
      authorities.OIK_DELETE,
      { deleteElement: "deleteOik" }
    );

    useInitTableStoreModule(root, SECTION_NAME, OikCatalogView as Module<unknown, unknown>);
    useOpenHistoryMode(root, "oikCatalogView", openModal);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, [], ["name", "code"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
      openModal();
    });
    // useGetTableLibs(root);

    return {
      filtersLoaded,
      showOikModal,
      type,
      sectionName,
      moveByAuthorities,
      isShowDeleteBtn,
      isShowRestoreBtn,
      openDeleteModal,
      openRestoreModal,
    };
  },
});
