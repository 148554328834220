import { IOikElement } from "@monorepo/catalog/src/views/OikView/types/oikElement";
import { convertStatus } from "@monorepo/catalog/src/views/ArchiveView/utils/convertApiItemToUi";
import moment from "moment";

export interface IExtendedOikElement extends IOikElement {
  dateStart: string;
  dateEnd: string;
  status: string;
  syncStatus: string;
  fundsList: Record<string, unknown>[];
}

export const convertApiItemToUi = (item: IOikElement): IExtendedOikElement => {
  const transferDates = item.transferHistory?.find(
    (dates: Record<string, string>) => new Date(dates.caseTransferStartDate).getFullYear().toString() === new Date().getFullYear().toString()
  );
  return {
    ...item,
    dateStart: item.oikContractDto?.dateStart || "-",
    dateEnd: item.oikContractDto?.dateEnd || "-",
    status: convertStatus(item.isDeleted),
    syncStatus: item.synchronizationStatus || "",
    fundsList: item.funds || [],
    caseTransferStartDate: transferDates?.caseTransferStartDate ? moment(transferDates.caseTransferStartDate).format("YYYY-MM-DD") : "",
    caseTransferEndDate: transferDates?.caseTransferEndDate ? moment(transferDates.caseTransferEndDate).format("YYYY-MM-DD") : "",
  };
};
